import React from 'react'
import { Container,  Header,  List, ListItem } from 'semantic-ui-react'
import { ResponsiveContainer } from './Home';
import '../App.css';

function GetInvolved() {
    return (
        // <ResponsiveContainer showHeading={false}>
        <Container text style={{ padding: 20, 'margin-top':'1em' }}>
            <Header as='h2'>Get Involved</Header>
            <Header as='h3'>Follow the journey of Community Cattle</Header>
            
            <List bulleted>
            <ListItem>
               Subscribe to our <a href='http://eepurl.com/ipbREc'>monthly newsletter</a> 
            </ListItem>
            <ListItem>
                Follow us on instagram: <a href='https://www.instagram.com/communitycattle/'>communitycattle</a>
            </ListItem>
            <ListItem>
                Email <a href="mailto:klenke@communitycattle.org">klenke@communitycattle.org</a> anytime!
            </ListItem>
            </List>

            <Header as='h3'>Volunteer</Header>
            <p>
            We welcome any ideas, resources or services you wish to share with Community Cattle in support of our mission.  A few suggested needs:
            </p>
            <List bulleted>
                <ListItem>
                    Discounted feed or hay
                </ListItem>
                <ListItem>
                    Veternarian services
                </ListItem>
                <ListItem>
                    Hauling cattle to processing
                </ListItem>
                <ListItem>
                    Processing services
                </ListItem>
            </List>

            <Header as='h3'>Donate</Header>
            <p>
                Your financial support will help Community Cattle feed our neighbors! Every dollar will be spent on the herd and the effort to get meat on the table for those in need.
            </p>
            <p>
                <i>Community Cattle Co. is a 501(c)(3) organization. Donors of a financial or in-kind gift will receive statements noting the date and value of the gift.</i>
            </p>
            <p>
            Please send all gifts of cash and checks to:
            </p>
            <div style={{'display':'flex', 'align-items': 'center', 'justify-content':'center'}}>
            <p >
                Community Cattle Co <br/>
                9238 Elizabeth Road <br/>
                Houston, TX 77055 
            </p>
            </div>

            <p style={{paddingTop: '2em'}}>Click here to donate online via PayPal. If you would like to support a sponsorship program, add a message to your donation with your chosen program and Quinn will reach out to you shortly</p>
            <div style={{
                'display':'flex',
                'align-items': 'center',
                'justify-content':'center',
            }}>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="KSDKKSCQR6CN4" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            </div>
            
            

            <Header as='h3'>Spread the Word</Header>
            <p>
                Share your contacts with food pantries and other local charities that serve meals who may wish to partner with us.
                <br></br>
                Spread the word to friends who may want to support this effort financially or can help with transportation and meat processing.
                <br></br>
                Pray for our mission and for an end to hunger.
            </p>

            <Header as='h3'>Sponsor a Steer!</Header>
            <p>
            We'll announce new births in our monthly newsletter. For $600, you can name your calf and
watch it grow! He'll get a personalized ear tag, and you can pay him a visit anytime. I'll send you
photos of your steer through the year.
            </p>
            <p>
            When he meets his destiny, the charity to whom we give his meat will receive a certificate
naming the family who provided for him.
            </p>

            <Header as='h3'>Sponsor a Cow for a year!</Header>
            <p>
            For $300, we'll add the name you choose to the ear tag of your cow for a year and visits are welcome.  Photos of your cow will be sent regularly.
            </p>
            <p>
            When she delivers a new calf, you will be the first to hear, as photos of the mama and baby come your way.
            </p>

            <Header as='h3'>Give to the Ferdinand Fund</Header>
            <p>
            He's already been named, but he could use a whole herd of supporters!  Any amount is welcome.  This fund will help cover new projects on the ranch, like an irrigation system, upgrading our pastures, a new corral, etc. 
            </p>

            <p>
            All contributors will be recognized in our newsletters, social media and website.  You will enjoy knowing that you are helping to fight hunger and providing healthy beef to our neighbors!  And you can count on our prayers for you and your families.
            </p>

            <p>
            I hope you will consider getting involved with Community Cattle and sharing in our mission.
            </p>
        </Container>
        // </ResponsiveContainer>
    )
} 

export default GetInvolved;