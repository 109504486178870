import React, { Fragment, useState } from "react";
import '../App.css'
import { Image, Icon, Header } from "semantic-ui-react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { cows, soldToAnotherRanch, deleciousMeat } from '../cows/cows'

function CowGallery() {
  const [data, setData] = useState({ img: '', index: 0 })

  const viewImage = (img, index) => {
    setData({img, index})
  }

  const imgAction = (action) => {
    let index = data.index
    if (action === 'next') {
      if (index < cows.length) {
        setData(cows[index + 1].src, index + 1)
      }
    } else if (action === 'prev') {
      if (index > 0) {
        setData(cows[index - 1].src, index - 1)
      }
    } else {
      setData('', 0)
    }
  }

  return (
    <>
      {data.img && 
        <div style={{
          width: '100%',
          height: '100vh',
          background: 'black',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}>
          <Icon onClick={() => imgAction('close')} name="close" style={{position: 'absolute', top: '10px', right: '10px'}}/>
          <Icon onClick={() => imgAction('next')} name="arrow alternate circle left outline" />
          <img src={data.img} style={{width: 'auto', maxWidth: '90%', maxHeight: '90%'}} />
          <Icon onClick={() => imgAction('prev')} name="arrow alternate circle right outline" />
        </div>
      }
      { data.img === '' ?
        <div style={{padding: '25px'}}>
          <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
            <Header content="The Herd" />
            <Masonry gutter="25px">
              {cows.map((data, index) => (
                <div  >
                  <Image key={index} src={data.src} style={{width: '100%', display: 'block'}} />
                  <p style={{ textAlign: 'center', width: 'auto'}}>
                      {data.title}
                  </p>
                </div>
              ))}
            </Masonry>

            <Header content="Sold to Another Ranch" />
            <Masonry gutter="25px">
              {soldToAnotherRanch.map((data, index) => (
                <div  >
                  <Image key={index} src={data.src} style={{width: '100%', display: 'block'}} />
                  <p style={{ textAlign: 'center', width: 'auto'}}>
                      {data.title}
                  </p>
                </div>
              ))}
            </Masonry>
            <Header content="Delicious Meat for the Communities We Serve" />
            <Masonry gutter="25px">
              {deleciousMeat.map((data, index) => (
                <div  >
                  <Image key={index} src={data.src} style={{width: '100%', display: 'block'}} />
                  <p style={{ textAlign: 'center', width: 'auto'}}>
                      {data.title}
                  </p>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div> : <Fragment/>
      }
    </>
  )
}
// onClick={() => viewImage(data.src, index)}>  
export default CowGallery;