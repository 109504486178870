import React from 'react'
import {
    Button,
    Container,
    Grid,
    Header,
    Image,
    Segment,
  } from 'semantic-ui-react'
import campHopeLogo from '../images/campHopeLogo.png';
import centerForPursuitLogo from '../images/centerForPursuitLogo.png';
import boysAndGirlsLogo from '../images/boysAndGirlsCountryLogo.jpg';
import '../App.css';

function CommunityWeServe() {
    return (
        <Container text >
            <Segment vertical>
                <Header as='h2'>Communities We Serve</Header>
            </Segment>
            
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                    <Grid.Column floated='left' width={4}>
                        <Image rounded size='large' src={campHopeLogo} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                        Camp Hope
                        </Header>
                        <p>
                        The PTSD Foundation of America provides hope and healing to veterans and their
families suffering from the effects of combat-related post-traumatic stress. We believe
every veteran deserves to be treated with dignity, respect, and compassion as they
work through their personal struggles with PTSD.
</p>
<p>
Our 501(c)(3) organization began in 2005 with a group of concerned volunteers who
searched for homeless veterans on the streets of Houston. Our mission is to drastically
reduce the veteran suicide rate and provide hope for a brighter future. We provide
programs, outreach services, and advocacy efforts designed to help veterans find the
tools they need to lead healthy lives after combat.
</p>
<p>
Camp Hope is a six- to nine-month treatment program designed to relieve the
effects of combat trauma. Based in Houston, Texas, Camp Hope's holistic approach
involves providing a residential program, peer support, and professional
development for America's warriors and their families.
                        </p>
                        <Button as='a' href='https://ptsdusa.org/'>
                            Visit their website
                        </Button>
                    </Grid.Column>
                    
                    </Grid.Row>
                    
                </Grid>
            </Segment>
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                    <Grid.Column floated='left' width={4}>
                        <Image rounded size='large' src={centerForPursuitLogo} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                        The Center for Pursuit
                        </Header>
                        <p>
                        For more than seven decades, The Center for Pursuit (The Center) has empowered individuals with
intellectual and developmental disabilities (IDD), autism, and similar conditions to live meaningful,
dignified lives filled with opportunity. Through innovative programs and services in areas like job skills
training, supported employment, health and wellness, community living, and day services, The Center
helps clients develop the skills and tools necessary to grow as individuals and participate fully in their
community. Now with expanded offerings for a younger demographic, the organization serves a total
client base of approximately 200 children, adolescents, and young adults and more than 450 adults in
the Houston area.
                        </p>
                        <Button as='a' href='https://thecenterforpursuit.org/programs-and-services/residential-services/'>
                            Visit their website
                        </Button>
                    </Grid.Column>
                    
                    </Grid.Row>
                    
                </Grid>
            </Segment>
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                    <Grid.Column floated='left' width={4}>
                        <Image rounded size='large' src={boysAndGirlsLogo} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                        Boys and Girls Country
                        </Header>
                        <p>
                        Children need love and nurturing to thrive, and they need food, clothing, shelter, healthcare
and education to grow up and become contributing adults. Children who come to Boys and
Girls Country (BGC) have experienced poverty, abuse, homelessness, food insecurity, and more.
All our kids come from circumstances which have made it difficult for them to have a good start
in life. Last year, BGC served 101 children and young adults.
</p>
<p>
Our residential program serves children ages 5-18, and our College and Career program serves
young adults ages 18-25. The College and Career program allows us to continue offering a
family for our kids to come home to beyond high school graduation as they follow their own,
individual paths to adulthood. Some of our young adults attend college or trade schools, others
join the military, and some choose to join the workforce.
                        </p>
                        <Button as='a' href='https://boysandgirlscountry.org/'>
                            Visit their website
                        </Button>
                    </Grid.Column>
                    
                    </Grid.Row>
                    
                </Grid>
            </Segment>

        </Container>
    )
}

export default CommunityWeServe;
 