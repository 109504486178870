import {Image, Menu, Grid, Header  } from "semantic-ui-react";
import React from 'react';
import logo from '../logo1.png'

export default function CommunityCattleHeader() {
    return (
            // <Grid centered>
            //     <Grid.Row>
               
            //     <Grid.Column width={14} verticalAlign='bottom'>
                    <Menu pointing secondary stackable>
                        <Menu.Menu position="left">
                        {/* <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/"}
                            onClick={() => document.location = "/"}
                            name="Home"
                        />     */}
                        {/* <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/home"}
                            onClick={() => document.location = "/home"}
                            name="Home"
                        /> */}
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/about"}
                            onClick={() => document.location = "/about"}
                            name="About"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/community"}
                            onClick={() => document.location = "/community"}
                            name="Communities we serve"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/getInvolved"}
                            onClick={() => document.location = "/getInvolved"}
                            name="Get Involved"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/partners"}
                            onClick={() => document.location = "/partners"}
                            name="Partners of the Herd"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/gallery"}
                            onClick={() => document.location = "/gallery"}
                            name="Gallery"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/newsletters"}
                            onClick={() => document.location = "/newsletters"}
                            name="Newsletters"
                        />
                         
                        {/* <Menu.Item
                            as='a' 
                            active={window.location.pathname === "/join"}
                            onClick={() => document.location = "/join"}
                            name="Get involved"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/service"}
                            onClick={() => document.location = "/service"}
                            name="How we serve"
                        />
                        <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/contact"}
                            onClick={() => document.location = "/contact"}
                            name="Contact us"
                        /> */}
                        {/* <Menu.Item 
                            as='a'
                            active={window.location.pathname === "/donate"}
                            onClick={() => document.location = "/donate"}
                            name="Donate now"
                        /> */}
                        </Menu.Menu>
                    
                {/* </Grid.Column> */}
                {/* <Grid.Column width={2} textAlign='left' verticalAlign='bottom'>
                    {/* <Header content="Community Cattle" as="h2" style={{ padding: '1em 1em' }}/> 
                    <Image src={logo} size='medium' />
                </Grid.Column> */}
                {/* </Grid.Row>
            </Grid> */}
            </Menu>
       
    )
}